import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { GoogleLogin, GoogleLogout, useGoogleLogin } from 'react-google-login';
import { AuthContext } from '../context/Auth.context';
import config from '../../config/config';
import blankProjectTemplate from '../utils/blankProjectTemplate';
import Button from '../Button';
import Link from '../Link';

const Page = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
`;

const Header = styled.h1`
  font-size: 64px;
  margin: 16px 0;
`;

const WelcomeUser = styled.div`
  border: 1px solid black;
  border-radius: 3px;
  padding: 12px;
`;

const List = styled.ul`
  list-style-type: none;
`;

const LandingPage = () => {
  const { setUser, user } = useContext(AuthContext);

  const responseGoogle = (response) => {
    const { profileObj } = response;

    fetch(`${config.database}/user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email_address: profileObj.email }),
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        setUser({
          email: response?.profileObj?.email,
          name: response?.profileObj?.givenName,
          userId: res.userId,
          tracks: res.tracks,
        });
      })
      .catch((e) => console.error('ERROR!'));
  };

  const responseGoogleLogout = () => {
    fetch(`${config.database}/logout`, {
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'ok') setUser(null);
      })
      .catch(() => {
        console.error('ERROR');
      });
  };

  const createNewTrack = () => {
    fetch(`${config.database}/track`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tracks: {},
        trackName: 'Untitled Project',
        totalTracks: 1,
        bpm: 120,
        beatsPerMeasure: 16,
        timeSignature: 4,
        totalMeasures: 8,
      }),
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        window.location = `/track/${res.id}${window.location.search}`;
      });
  };

  return (
    <Page>
      <Header>tab.cafe</Header>
      <p>Free and easy guitar tablature</p>
      {user?.userId ? (
        <WelcomeUser>
          <p>
            👋, {user.name}! (Not you?{' '}
            <GoogleLogout
              clientId="307568849954-v4tkptvobtq2nstd9m82gnosf7eghiqn.apps.googleusercontent.com"
              onLogoutSuccess={responseGoogleLogout}
              render={({ onClick, disabled }) => (
                <Button onClick={onClick} disabled={disabled}>
                  Sign out
                </Button>
              )}
            />
            )
          </p>
          <p>
            Choose a project below, or{' '}
            <Button onClick={() => createNewTrack()}>create a new one</Button>
          </p>
          <List>
            {user.tracks.map(({ id, trackName }) => {
              return (
                <li key={id}>
                  <Link href={`/track/${id}${window.location.search}`}>
                    {trackName}
                  </Link>
                </li>
              );
            })}
          </List>
        </WelcomeUser>
      ) : (
        <GoogleLogin
          clientId="307568849954-v4tkptvobtq2nstd9m82gnosf7eghiqn.apps.googleusercontent.com"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
          isSignedIn={true}
          render={({ onClick, disabled }) => (
            <Button onClick={onClick} disabled={disabled}>
              Sign in with Google
            </Button>
          )}
        />
      )}
    </Page>
  );
};

export default LandingPage;
