import React, { createContext, useEffect, useState } from 'react';
import blankProjectTemplate from '../utils/blankProjectTemplate';
import createMeasure from '../utils/createMeasure';
import createTrack from '../utils/createTrack';
import getTrack from '../utils/getTrack';
import config from '../../config/config';

const params = new URLSearchParams(window.location.search);
const withAuth = params.get('withAuth');

export const TrackContext = createContext({});

const TrackProvider = ({ children, id }) => {
  const [project, setProject] = useState(null);

  useEffect(() => {
    if (withAuth && id !== 'new') {
      const fetchTrack = getTrack(id);

      fetchTrack
        .then((res) => {
          const { bpm, beatsPerMeasure, totalMeasures, tracks, readOnly } = res;

          setProject({
            id,
            trackName: res.trackName,
            readOnly,
            ...blankProjectTemplate({
              bpm,
              beatsPerMeasure,
              totalMeasures,
              tracks,
            }),
          });
        })
        .catch((e) => {
          console.error('Cannot load track', e);
          setProject(blankProjectTemplate());
        });
    } else {
      setProject({ id, ...blankProjectTemplate() });
    }
  }, []);

  const addNewTrack = () => {
    const newTrackNumber = project.totalTracks + 1;
    const measures = [...Array(project.totalMeasures).keys()];

    const track = measures.map((measure) => {
      return createMeasure(measure);
    });

    setProject({
      ...project,
      totalTracks: newTrackNumber,
      tracks: [...project.tracks, createTrack(newTrackNumber, track)],
    });
  };

  const addMoreMeasures = () => {
    const { totalMeasures: prevMeasureAmount } = project;
    const range = [];
    const newMeasureAmount = prevMeasureAmount + 1;

    for (let i = prevMeasureAmount; i < newMeasureAmount; i++) {
      range.push(i);
    }

    const newMeasures = range.map((num) => createMeasure(num + 1));

    setProject({
      ...project,
      totalMeasures: newMeasureAmount,
      tracks: project.tracks.map((track) => {
        return {
          ...track,
          track: [...track.track, ...newMeasures],
        };
      }),
    });
  };

  const setNewInstrument = (choice, trackNumber) => {
    setProject({
      ...project,
      tracks: project.tracks.map((track) => {
        if (track.trackNumber === trackNumber) {
          return {
            ...track,
            instrument: choice,
          };
        }

        return track;
      }),
    });
  };

  const updateProjectName = (name) => {
    fetch(`${config.database}/track/trackName`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ trackName: name, id }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'ok') {
          setProject({
            ...project,
            trackName: name,
          });
        }
      });
  };

  const changeTuning = (e, trackNumber) => {
    const { value } = e.target;

    setProject({
      ...project,
      tracks: project.tracks.map((track) => {
        if (track.trackNumber === trackNumber) {
          return {
            ...track,
            tuning: value,
          };
        }

        return track;
      }),
    });
  };

  return (
    project && (
      <TrackContext.Provider
        value={{
          project,
          setProject,
          addMoreMeasures,
          addNewTrack,
          setNewInstrument,
          updateProjectName,
          changeTuning,
        }}>
        {children}
      </TrackContext.Provider>
    )
  );
};

export default TrackProvider;
