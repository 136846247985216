import { tuning, beats, initialBeatsPerMeasure } from '../defaults';

const createMeasure = (
  measure,
  beatsPerMeasure = initialBeatsPerMeasure,
  notesInMeasure = {}
) => {
  const notesInBar = notesInMeasure.bars?.map((n) => n.notes).flat() || [];

  return {
    measure,
    bars: beats.map((beat) => {
      const _beat = beat + 1;

      return {
        position: beatsPerMeasure * (measure - 1) + (beat + 1),
        notes: tuning.map((_, i) => {
          const lineNumber = i + 1;
          const note = notesInBar?.find((n) => {
            return n.beat === _beat && n.lineNumber === lineNumber;
          });

          if (note) {
            return note;
          }

          return {
            lineNumber,
            measure: measure,
            duration: `${beatsPerMeasure}n`,
            beat: _beat,
            note: '',
            fret: '',
          };
        }),
      };
    }),
  };
};

export default createMeasure;
