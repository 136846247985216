import React from 'react';
import styled from 'styled-components';

const StyledLink = styled.a`
  border: none;
  background-color: white;
  color: black;
  transition: background-color 0.1s linear, color 0.2s linear;
  font-family: 'Roboto Mono', monospace;
  text-decoration: underline;
  font-weight: 700;
`;

const Link = ({ children, href }) => (
  <StyledLink href={href}>{children}</StyledLink>
);

export default Link;
