import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  border: none;
  padding: 8px 16px;
  background-color: white;
  color: black;
  transition: background-color 0.1s linear, color 0.2s linear;
  border-radius: 3px;
  font-family: 'Roboto Mono', monospace;
  text-decoration: underline;
  font-weight: 700;

  ${({ disabled }) =>
    disabled
      ? `opacity: 0.7; cursor: not-allowed;`
      : `
      &:hover {
        background-color: black;
        color: white;
        cursor: pointer;
      }
  `}
`;

const Button = ({ children, disabled, onClick, ...rest }) => (
  <StyledButton disabled={disabled} onClick={onClick} {...rest}>
    {children}
  </StyledButton>
);

export default Button;
