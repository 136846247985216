const config = {
  development: {
    database: 'http://localhost:1337',
  },
  production: {
    database: 'https://tabcafe.herokuapp.com',
  },
};

export default config[process.env.NODE_ENV];
