import React, { createContext, useState } from 'react';
import { beatsPerMeasureOptions, initialBeatsPerMeasure } from '../defaults';

export const ToneContext = createContext({});

const ToneProvider = ({ children }) => {
  const [bpm, setBpm] = useState(120);
  const [beatsPerMeasure, setBeatsPerMeasure] = useState(
    initialBeatsPerMeasure
  );

  return (
    <ToneContext.Provider
      value={{
        bpm,
        setBpm,
        beatsPerMeasure,
        setBeatsPerMeasure,
        beatsPerMeasureOptions,
      }}>
      {children}
    </ToneContext.Provider>
  );
};

export default ToneProvider;
