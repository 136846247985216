import React, { useContext, useEffect, useState } from 'react';
import * as Tone from 'tone';
import styled from 'styled-components';
import Input from '../Input';
import { dispatchDomEvent } from '../events/dispatchDomEvent';

const StyledContainer = styled.div`
  margin-right: 8px;
`;

const bpmInput = ({ bpm }) => {
  const [amount, set] = useState(bpm);

  useEffect(() => {
    if (amount) Tone.Transport.bpm.value = amount;

    if (!amount) Tone.Transport.bpm.value = 0;

    dispatchDomEvent('updateTrackAttribute', { name: 'bpm', value: amount });
  }, [amount]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  return (
    <StyledContainer>
      <Input
        label="BPM:"
        id="bpm"
        value={amount}
        onChange={(e) => set(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e)}
        type="number"
        width="50px"
      />
    </StyledContainer>
  );
};

export default bpmInput;
