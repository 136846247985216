import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import LandingPage from './landing_page';
import App from './App';
import ToneProvider from './context/Tone.context';
import TrackProvider from './context/Track.context';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import AuthProvider from './context/Auth.context';
const params = new URLSearchParams(window.location.search);
const withAuth = params.get('withAuth');

const TrackEditor = ({ id }) => (
  <ToneProvider>
    <TrackProvider id={id}>
      <App />
    </TrackProvider>
  </ToneProvider>
);

const FullApplication = () => {
  return (
    <AuthProvider>
      <Router>
        <Route exact path="/">
          <LandingPage />
        </Route>
        <Route
          path={`/track/:id`}
          render={(props) => {
            const id = props.match.params.id;

            return <TrackEditor id={id} />;
          }}
        />
      </Router>
    </AuthProvider>
  );
};

ReactDOM.render(
  withAuth ? <FullApplication /> : <TrackEditor />,
  document.getElementById('root')
);
