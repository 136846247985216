import React from 'react';
import styled from 'styled-components';

const StyledInputContainer = styled.div`
  display: flex;
  font-size: 16px;
`;

const StyledInput = styled.input`
  border: none;
  border-bottom: 1px solid black;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  padding-left: 8px;
  font-family: 'Roboto Mono', monospace;
  font-weight: 700;

  &:focus {
    outline: none;
  }
`;

const StyledLabel = styled.label``;

const Input = ({ label, onChange, onKeyDown, value, id, type, width }) => {
  return (
    <StyledInputContainer>
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
      <StyledInput
        id={id}
        value={value}
        onChange={onChange}
        type={type}
        onKeyDown={onKeyDown}
        style={{ width }}
      />
    </StyledInputContainer>
  );
};

export default Input;
