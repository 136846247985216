import React, { useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { dispatchDomEvent } from './events/dispatchDomEvent';
import { useCustomEventListener } from './events/useCustomEventListener';

const Container = styled.span`
  position: relative;
  display: inline-block;
  height: 100%;

  &::before {
    content: ' ';
    position: absolute;
    z-index: 1;
    top: 50%;
    width: 100%;
    height: 1px;
    background-color: black;
  }
`;

const NoteInput = styled.input.attrs({
  type: 'text',
})`
  position: relative;
  z-index: 2;
  height: 24px;
  width: 18px;
  border: none;
  outline: none;
  padding: 0;
  background: ${({ value, currentlyPlaying, beat, highlighted }) => {
    if (currentlyPlaying) {
      return `lightblue`;
    }

    if (highlighted) {
      return `rgba(253, 253, 150, 0.5)`;
    }

    /**
     * If value is ANY number cast to string so its truthy
     */
    if (String(value)) {
      return `white`;
    }

    if (beat % 4 === 1) {
      return `rgba(0, 0, 0, 0.1)`;
    }

    return 'transparent';
  }};

  text-align: center;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Note = (props) => {
  const [note, setNote] = useState(props);
  const [highlighted, setHighlighted] = useState(false);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(false);
  const { fret } = note;
  const noteRef = useRef(null);

  /**
   * Listens for when a note receives focus.
   *
   * A note can receive focus through keyboard navigation and clicking on a note.
   */
  useCustomEventListener(
    `noteFocus`,
    useCallback(
      (e) => {
        const { position: newposition, lineNumber: newLineNumber } = e.detail;

        if (
          newposition === note.position &&
          newLineNumber === note.lineNumber
        ) {
          noteRef.current.focus();
          noteRef.current.select();
        }
      },
      [noteRef]
    )
  );

  useCustomEventListener(
    `noteCopy:${note.position}`,
    useCallback((e) => {
      const { position, isCutEvent } = e.detail;
      const notesToCopy = window.sessionStorage.getItem('notesToCopy');
      const parsed = JSON.parse(notesToCopy);

      /**
       * Removes the value from this note, but puts it into the stack for pasting.
       *
       * Similar to a native cut event.
       */
      if (isCutEvent) {
        onUpdateNote({
          value: '',
        });
      }

      if (note.note) {
        window.sessionStorage.setItem(
          'notesToCopy',
          JSON.stringify([
            ...(parsed || []),
            { column: position, row: note.lineNumber, note },
          ])
        );
      }
    })
  );

  useCustomEventListener(
    `notePaste:${note.position}`,
    useCallback((e) => {
      const { lineNumber, note: newNote } = e.detail;

      if (lineNumber === note.lineNumber) {
        onUpdateNote({
          value: newNote.fret,
        });
      }
    })
  );

  useCustomEventListener(
    `noteSelect:${note.position}`,
    useCallback((e) => {
      const { isHighlighted } = e.detail;

      if (!props.readOnly) setHighlighted(isHighlighted);
    })
  );

  useCustomEventListener(
    `noteRemove:${note.position}`,
    useCallback(() => {
      // only trigger removals on spaces with notes
      if (note.note) {
        onUpdateNote({
          value: '',
        });
      }
    })
  );

  useCustomEventListener(
    `trackClear:${note.trackNumber}`,
    useCallback(() => {
      if (note.note) {
        onUpdateNote({
          value: '',
        });
      }
    })
  );

  /**
   * Most other events will target a bar (just using note.position)
   *
   * This event listener targets an individual note and moves it.
   */
  useCustomEventListener(
    `noteMove:${note.position}:${note.lineNumber}`,
    useCallback((e) => {
      const { newNote } = e.detail;

      onUpdateNote({
        value: newNote.fret,
      });
    })
  );

  /**
   * When a track is playing, notes will listen for when they are being played.
   *
   * If they are, they get highlighted
   */
  useCustomEventListener(`playingPosition:${note.position}`, (e) => {
    setCurrentlyPlaying(e.detail.playing);
  });

  /**
   * After a note is confirmed to be valid in a higher level component, this event is triggered to confirm the new note.
   *
   * The note is also sent to a separate component to be registered for saving
   */
  useCustomEventListener(
    `registerNoteChange:${note.position}:${note.lineNumber}:${note.beat}`,
    (e) => {
      unhighlightNotes();

      dispatchDomEvent(`saveNote`, { note: e.detail.note });

      setNote(e.detail.note);
    }
  );

  const handleShiftKeyPress = (destination) => {
    handleHighlight();
  };

  const handleAltKeyPress = (originLocation, targetLocation) => {
    dispatchDomEvent(`noteMove:${originLocation}`, {
      newNote: {
        ...note,
        note: '',
        fret: '',
      },
    });

    dispatchDomEvent(`noteMove:${targetLocation}`, {
      newNote: note,
    });
  };

  const onUpdateNote = (props) => {
    const { value } = props;
    const fret = parseInt(value, 10);

    if ((fret >= 0 && fret <= 24) || value === '') {
      dispatchDomEvent('updateNote', { note, updatedFret: fret });
    }
  };

  /**
   *
   * @param {SyntheticEvent} e - passed from an event handler
   * @param {boolean} isCutEvent - notifies the noteCopy event if it should behave as a cut event
   */
  const copy = (e, isCutEvent) => {
    const highlightedNotes = JSON.parse(
      window.sessionStorage.getItem('highlightedNotes')
    );

    if (highlightedNotes) {
      e.preventDefault();
      window.sessionStorage.setItem('useBulkCopy', 1);
      window.sessionStorage.setItem('notesToCopy', '[]');

      highlightedNotes
        .sort((a, b) => a - b)
        .forEach((note, i) => {
          dispatchDomEvent(`noteCopy:${note}`, {
            position: i + 1,
            isCutEvent,
          });
        });
    } else {
      window.sessionStorage.setItem('useBulkCopy', 0);
    }
  };

  const handleKeyPress = (e) => {
    const arrowKeys = [37, 38, 39, 40];
    const { keyCode, metaKey, altKey, shiftKey } = e;
    const { position, lineNumber } = note;

    if (arrowKeys.includes(e.keyCode)) {
      e.preventDefault();
    }

    if (shiftKey && !arrowKeys.includes(keyCode)) {
      handleHighlight();
    }

    // left
    if (keyCode === 37) {
      if (altKey) {
        handleAltKeyPress(
          `${note.position}:${note.lineNumber}`,
          `${note.position - 1}:${note.lineNumber}`
        );
      }

      if (shiftKey) {
        handleShiftKeyPress(position - 1);
      }

      dispatchDomEvent(`noteFocus`, {
        position: position - 1,
        lineNumber,
      });
    }

    // right
    if (keyCode === 39) {
      if (altKey) {
        handleAltKeyPress(
          `${note.position}:${note.lineNumber}`,
          `${note.position + 1}:${note.lineNumber}`
        );
      }

      if (shiftKey) {
        handleShiftKeyPress(note.position + 1);
      }

      dispatchDomEvent(`noteFocus`, {
        position: position + 1,
        lineNumber,
      });
    }

    // up
    if (keyCode === 38) {
      if (altKey) {
        handleAltKeyPress(
          `${note.position}:${note.lineNumber}`,
          `${note.position}:${note.lineNumber - 1}`
        );
      }

      dispatchDomEvent(`noteFocus`, {
        position,
        lineNumber: lineNumber - 1,
      });
    }

    // down
    if (keyCode === 40) {
      if (altKey) {
        handleAltKeyPress(
          `${note.position}:${note.lineNumber}`,
          `${note.position}:${note.lineNumber + 1}`
        );
      }

      dispatchDomEvent(`noteFocus`, {
        position,
        lineNumber: lineNumber + 1,
      });
    }

    // backspace
    if (keyCode === 8 && metaKey) {
      const highlightedNotes = JSON.parse(
        window.sessionStorage.getItem('highlightedNotes')
      );

      highlightedNotes.forEach((position) => {
        dispatchDomEvent(`noteRemove:${position}`);
      });
    }
  };

  const handleKeyUp = (e) => {
    const { keyCode } = e;

    /**
     * Detect esc (27) press
     */
    if (keyCode === 27) unhighlightNotes();
  };

  const handleCut = (e) => copy(e, true);

  const handleCopy = (e) => copy(e, false);

  const handlePaste = (e) => {
    const useBulkCopy = JSON.parse(
      window.sessionStorage.getItem('useBulkCopy')
    );

    if (useBulkCopy) {
      e.preventDefault();
      const { position } = note;
      const notesToCopy = window.sessionStorage.getItem('notesToCopy');
      const parsed = JSON.parse(notesToCopy);

      parsed.forEach(({ column, row, note }) => {
        const newLocation = position + column - 1;

        dispatchDomEvent(`notePaste:${newLocation}`, {
          lineNumber: row,
          note,
        });
      });
    }
  };

  const handleHighlight = () => {
    const highlightedNotes = window.sessionStorage.getItem('highlightedNotes');
    /**
     * Get the array, or use the current note position with 1 subtracted to treat it an an array index which helps calculate the range correctly
     */
    const arr = JSON.parse(highlightedNotes) || [note.position];

    if (arr[0] === note.position) {
      dispatchDomEvent(`noteSelect:${note.position}`, {
        isHighlighted: true,
      });

      window.sessionStorage.setItem('highlightedNotes', JSON.stringify(arr));
    } else {
      const modifier = note.position < arr[0] ? -1 : 1;
      const range = Array(
        Math.abs(note.position + modifier - arr[0] - modifier)
      )
        .fill()
        .map((_, i) => arr[0] + i * modifier);

      if (arr.length > range.length) {
        const lengthDiff = arr.length - range.length;
        const notesToUnhighlight = arr.slice(-lengthDiff);

        notesToUnhighlight.forEach((position) => {
          dispatchDomEvent(`noteSelect:${position}`, {
            isHighlighted: false,
          });
        });
      }

      range.forEach((position) => {
        dispatchDomEvent(`noteSelect:${position}`, {
          isHighlighted: true,
        });
      });

      window.sessionStorage.setItem('highlightedNotes', JSON.stringify(range));
    }
  };

  const handleMouseLeave = (e) => {
    e.preventDefault();

    if (e.buttons === 1) {
      handleHighlight();
    }
  };

  const unhighlightNotes = () => {
    const highlightedNotes =
      JSON.parse(window.sessionStorage.getItem('highlightedNotes')) || [];

    highlightedNotes.forEach((bar) => {
      dispatchDomEvent(`noteSelect:${bar}`, {
        isHighlighted: false,
      });
    });

    window.sessionStorage.removeItem('highlightedNotes');
  };

  return (
    <Container>
      <NoteInput
        ref={noteRef}
        onChange={(e) =>
          onUpdateNote({
            value: e.target.value,
          })
        }
        onFocus={() => {
          dispatchDomEvent('noteFocus', {
            position: note.position,
          });
        }}
        onKeyDown={(e) => handleKeyPress(e)}
        onKeyUp={(e) => handleKeyUp(e)}
        value={fret >= 0 ? fret : ''}
        min={0}
        max={24}
        beat={props.beat}
        currentlyPlaying={currentlyPlaying}
        onCut={(e) => handleCut(e)}
        onCopy={(e) => handleCopy(e)}
        onPaste={(e) => handlePaste(e)}
        onMouseDown={(e) => unhighlightNotes()}
        onMouseLeave={(e) => handleMouseLeave(e)}
        highlighted={highlighted}
        disabled={props.readOnly}
      />
    </Container>
  );
};

export default Note;
