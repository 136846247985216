import React, { useEffect, useReducer, useState, useRef } from 'react';
import * as instruments from '../tone/instruments';
import * as Tone from 'tone';
import { dispatchDomEvent } from '../events/dispatchDomEvent';
import { useCustomEventListener } from '../events/useCustomEventListener';
import FontAwesome from 'react-fontawesome';
import AudioReducer from '../reducers/audio';
import { notes, guitarTunings } from '../defaults';
import Button from '../Button';

const PlayButton = (props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [focusedPosition, setFocusedPosition] = useState(null);
  const [playFromLocation, setPlayFromLocation] = useState(false);
  const [audio, dispatch] = useReducer(AudioReducer, []);
  const currentPosition = useRef(null);
  const handleKeyDown = (e) => {
    const { shiftKey, altKey, code } = e;

    /**
     * Toggles both states
     */
    if (shiftKey && altKey && code === 'Space') {
      setPlayFromLocation((prevState) => !prevState);
      setIsPlaying((prevState) => !prevState);
    }

    /**
     * Toggles isPlaying only
     */
    if (shiftKey && code === 'Space' && !altKey) {
      setPlayFromLocation(false);
      setIsPlaying((prevState) => !prevState);
    }
  };

  useCustomEventListener('updateNote', (e) => {
    const { note, updatedFret } = e.detail;
    const updatedFretInt = parseInt(updatedFret, 10);
    const { trackNumber, duration, lineNumber } = note;
    const currentTrack = props.template.tracks[trackNumber - 1];
    const { instrument, tuning } = currentTrack;
    const { beatsPerMeasure } = props.template;
    const baseNote = guitarTunings[tuning][lineNumber - 1];
    const noteIndex = notes.indexOf(baseNote);
    let newNote = null;

    if (updatedFret !== 0 && !updatedFret) {
      newNote = {
        ...note,
        note: '',
        fret: '',
      };
    } else {
      newNote = {
        ...note,
        note: notes[noteIndex + updatedFretInt],
        fret: updatedFretInt,
      };
    }

    if (newNote.note) {
      instruments[instrument].triggerAttackRelease(
        newNote.note,
        beatsPerMeasure > 8 ? `8n` : duration
      );
    }

    if (newNote) {
      dispatchDomEvent(
        `registerNoteChange:${note.position}:${note.lineNumber}:${note.beat}`,
        { note: newNote }
      );

      /**
       * Sends the note to the reducer
       */
      dispatch({ type: 'updateNote', payload: newNote });
    }
  });

  /**
   * Gets the currently focused note from an event emitter in Note.js
   */
  useCustomEventListener('noteFocus', (e) => {
    const { position } = e.detail;
    setFocusedPosition(position);
  });

  /**
   * On load, register any notes into the audio.
   */
  useEffect(() => {
    const { tracks } = props.template;

    tracks.forEach((t) => {
      t.track.forEach((b) => {
        b.bars.forEach((n) => {
          n.notes.forEach((note) => {
            if (note.note) {
              dispatch({ type: 'updateNote', payload: note });
            }
          });
        });
      });
    });

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    let positionInSong = playFromLocation ? focusedPosition - 1 : 0;
    if (isPlaying) {
      Tone.Transport.scheduleRepeat((time) => {
        if (positionInSong - 1 > -1) {
          dispatchDomEvent(`playingPosition:${positionInSong - 1}`, {
            playing: false,
          });
        }

        dispatchDomEvent(`playingPosition:${positionInSong}`, {
          playing: true,
        });

        const currentBar = audio[positionInSong];

        if (currentBar) {
          currentBar.forEach((bar) => {
            if (bar?.note) {
              const { trackNumber } = bar;
              const instrument =
                props.template.tracks[trackNumber - 1].instrument;

              instruments[instrument].triggerAttackRelease(
                bar.note,
                bar.duration,
                time
              );
            }
          });
        }

        positionInSong++;
        currentPosition.current = positionInSong;
      }, `${props.template.beatsPerMeasure}n`);

      Tone.Transport.start();
    } else {
      Tone.Transport.stop();
      Tone.Transport.cancel();

      const low = currentPosition.current - 5;

      const lowBound = low < 1 ? 1 : low;
      const highBound = currentPosition.current + 5;

      const range = Array(highBound - lowBound)
        .fill()
        .map((_, i) => lowBound + i);

      range.forEach((position) => {
        dispatchDomEvent(`playingPosition:${position}`, {
          playing: false,
        });
      });
    }
  }, [isPlaying]);

  return (
    <>
      <Button
        disabled={isPlaying}
        onClick={() => {
          Tone.start();
          setPlayFromLocation(false);
          setIsPlaying(true);
        }}>
        <FontAwesome name="play" />
      </Button>
      <Button
        disabled={!focusedPosition || isPlaying}
        onClick={() => {
          Tone.start();
          setPlayFromLocation(true);
          setIsPlaying(!isPlaying);
        }}>
        <FontAwesome name="play-circle" />
      </Button>
      <Button
        disabled={!isPlaying}
        onClick={() => {
          setPlayFromLocation(false);
          setIsPlaying(false);
        }}>
        <FontAwesome name="stop" />
      </Button>
    </>
  );
};

export default PlayButton;
