import config from '../../config/config';

const getTrack = (id) => {
  return fetch(`${config.database}/track/${id}`, {
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export default getTrack;
