import { useCallback, useEffect } from 'react';

export const useCustomEventListener = (event, callback) => {
  useEffect(() => {
    document.addEventListener(event, callback);

    return () => document.removeEventListener(event, callback);
  }, [event, callback]);
};

export default {};
