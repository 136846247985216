import { instrument } from '../defaults';

/**
 * A factory for creating new tracks
 *
 * @param {Number} trackNumber - the 1-based number of the track
 * @param {Array} measures - an array of measures passed in
 */
const createTrack = (trackNumber, measures) => {
  return {
    instrument,
    trackNumber,
    tuning: 'standard',
    track: measures,
  };
};

export default createTrack;
