import React, { useEffect, useState, useReducer, useRef } from 'react';
import FontAwesome from 'react-fontawesome';
import { useCustomEventListener } from './events/useCustomEventListener';
import * as Tone from 'tone';
import UIReducer from './reducers/ui';
import config from '../config/config';
import Button from './Button';

const params = new URLSearchParams(window.location.search);
const withAuth = params.get('withAuth');

const SaveInLocalStorage = (props) => {
  const { template } = props;
  const [state, dispatch] = useReducer(UIReducer, template);
  const [trackIsSaved, setTrackIsSaved] = useState(false);
  const amountOfChangesMade = useRef(0);
  let params = new URLSearchParams(window.location.search);
  const handleBeforeUnload = (e) => {
    if (name) {
      saveTrack();
    }
  };

  useCustomEventListener('saveNote', (e) => {
    dispatch({ type: 'updateNote', payload: e.detail.note });
  });

  useCustomEventListener('updateTrackAttribute', (e) => {
    const { name, value } = e.detail;

    dispatch({ type: 'updateTrackAttribute', payload: { name, value } });
  });

  useEffect(() => {
    amountOfChangesMade.current++;

    if (amountOfChangesMade.current > 10) {
      saveTrackToDB();
      amountOfChangesMade.current = 0;
    }
  }, [state]);

  /**
   * Detect changes from the template and update accordingly in the local save state
   */
  useEffect(() => {
    if (template.tracks[0].track.length !== state.tracks[0].track.length) {
      dispatch({ type: 'addNewMeasure', payload: template.tracks[0].track });
    }

    if (template.tracks[0].tuning !== state.tracks[0].tuning) {
      dispatch({ type: 'updateTuning', payload: template.tracks[0].tuning });
    }
  }, [template]);

  useEffect(() => {
    if (trackIsSaved) {
      setTimeout(() => {
        setTrackIsSaved(false);
      }, 3000);
    }
  }, [trackIsSaved]);

  const saveTrack = () => {
    const trackName = name || prompt('What is the name of the track?', name);
    if (trackName) {
      window.localStorage.setItem(
        trackName,
        JSON.stringify({
          ...state,
          projectName: trackName,
          bpm: Tone.Transport.bpm.value,
          timeSignature: Tone.Transport.timeSignature,
        })
      );

      params.set('load', trackName);

      window.history.replaceState(
        {},
        `${trackName} - tab.cafe`,
        `${window.location.pathname}?${params.toString()}`
      );

      props.updateProjectName(trackName);

      setTrackIsSaved(true);
    }
  };

  const saveTrackToDB = () => {
    const onlyNonNullNotes = state.tracks.map(({ track, ...rest }) => {
      return {
        ...rest,
        track: track
          .map(({ bars, ...rest }) => {
            const newBars = bars
              .map(({ notes, ...rest }) => {
                const newNotes = notes.filter(({ note }) => {
                  return !!note;
                });

                if (newNotes.length) {
                  return {
                    ...rest,
                    notes: newNotes,
                  };
                }

                return null;
              })
              .filter(Boolean);

            if (newBars.length) {
              return {
                ...rest,
                bars: newBars,
              };
            }

            return null;
          })
          .filter(Boolean),
      };
    });

    const body = {
      id: state.id,
      trackName: state.trackName,
      beatsPerMeasure: state.beatsPerMeasure,
      bpm: state.bpm,
      timeSignature: state.timeSignature,
      totalMeasures: state.totalMeasures,
      totalTracks: state.totalTracks,
      tracks: onlyNonNullNotes,
    };

    fetch(`${config.database}/track`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'ok') {
          setTrackIsSaved(true);
        }

        if (window.location.pathname.includes('new')) {
          window.history.replaceState(
            null,
            '',
            `/track/${res._id}${window.location.search}`
          );
        }
      })
      .catch((e) => console.error('there was an issue saving'));
  };

  return (
    <>
      <Button
        onClick={() => (!withAuth ? saveTrack() : saveTrackToDB())}
        disabled={trackIsSaved}>
        <FontAwesome name={trackIsSaved ? 'check' : 'save'} />
      </Button>
    </>
  );
};

export default SaveInLocalStorage;
