import React from 'react';
import Play from './hooks/Play';
import SaveInLocalStorage from './SaveInLocalStorage';
import Button from './Button';

const ActionsBar = (props) => {
  return (
    <div>
      <Play template={props.template} />
      {!props.readOnly && (
        <SaveInLocalStorage
          template={props.template}
          updateProjectName={props.updateProjectName}
        />
      )}
    </div>
  );
};

export default ActionsBar;
