const AudioReducer = (state, action) => {
  switch (action.type) {
    case 'updateNote':
      const { payload } = action;

      const { position, lineNumber, note } = payload;

      let newBar = [];

      if (!state[position - 1]) {
        newBar.push(payload);
      } else {
        if (!note) {
          newBar = state[position - 1]
            .map((noteInBar) => {
              if (
                noteInBar.position === position &&
                noteInBar.lineNumber === lineNumber
              ) {
                return payload;
              }

              return noteInBar;
            })
            .filter((noteInBar) => noteInBar.note);
        }

        if (note) {
          newBar = [
            ...state[position - 1].filter(
              (noteInBar) => noteInBar.lineNumber !== lineNumber
            ),
            payload,
          ];
        }
      }

      return Object.assign(state, {
        [position - 1]: newBar,
      });

    default:
      console.error('Error.');
  }
};

export default AudioReducer;
