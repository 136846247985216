import React, { useContext } from 'react';
import styled from 'styled-components';
import Note from './Note';

const StyledMeasure = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    right: 0;
    height: 100%;
    width: 1px;
    background-color: black;
  }
`;

const StyledBeatContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledBar = styled.div`
  display: flex;
  flex-direction: column;
`;

const BaseNote = styled.div`
  padding-right: 16px;
  border-right: 1px solid black;
`;

const Measure = ({ bars, measure, trackNumber, readOnly }) => {
  return (
    <StyledMeasure measure={measure}>
      <div>{measure}</div>
      <StyledBeatContainer>
        {bars.map((bar) => {
          return (
            <StyledBar key={`${bar.position}_${measure}`}>
              {bar.notes.map((note) => {
                return (
                  <Note
                    key={`${note.lineNumber}_${measure}_${note.beat}`}
                    measure={measure}
                    trackNumber={trackNumber}
                    position={bar.position}
                    readOnly={readOnly}
                    {...note}
                  />
                );
              })}
            </StyledBar>
          );
        })}
      </StyledBeatContainer>
    </StyledMeasure>
  );
};

export default Measure;
