const UIReducer = (state, action) => {
  const { payload } = action;

  switch (action.type) {
    case 'updateNote':
      const { lineNumber, measure, beat } = payload;

      return {
        ...state,
        tracks: state.tracks.map((t) => {
          return {
            ...t,
            track: t.track.map((b) => {
              if (measure === b.measure) {
                return {
                  ...b,
                  bars: b.bars.map((bar) => {
                    return {
                      ...bar,
                      notes: bar.notes.map((note) => {
                        if (
                          note.beat === beat &&
                          note.lineNumber === lineNumber
                        ) {
                          return payload;
                        }

                        return note;
                      }),
                    };
                  }),
                };
              }

              return b;
            }),
          };
        }),
      };

    case 'addNewMeasure':
      const totalNewMeasures = payload.length;
      const totalCurrentMeasures = state.totalMeasures;
      const measuresToAddToTracks = +payload.length - +totalCurrentMeasures;

      return {
        ...state,
        totalMeasures: totalNewMeasures,
        tracks: state.tracks.map((t, i) => {
          return {
            ...t,
            track: [...t.track, ...payload.slice(-measuresToAddToTracks)],
          };
        }),
      };

    case 'updateTuning':
      return {
        ...state,
        tracks: state.tracks.map((t) => {
          return {
            ...t,
            tuning: payload,
          };
        }),
      };

    case 'updateTrackAttribute':
      const { name, value } = payload;

      if (state[name]) {
        return {
          ...state,
          [name]: value,
        };
      } else {
        return state;
      }
    default:
      console.error('Error.');
  }
};

export default UIReducer;
