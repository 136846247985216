/**
 * Provides all the data for a blank UI, with application-level project defaults
 */

import {
  initialBeatsPerMeasure,
  defaultBpm,
  initialMeasures,
} from '../defaults';
import createTrack from './createTrack';
import createMeasure from './createMeasure';

const generateTemplate = ({
  bpm = defaultBpm,
  beatsPerMeasure = initialBeatsPerMeasure,
  totalMeasures = initialMeasures,
  totalTracks = 1,
  tracks = [],
} = {}) => {
  const measures = [...Array(totalMeasures).keys()];

  const trackWithNotes = tracks.length === 1 ? tracks[0].track : [];

  const track = measures.map((measure) => {
    const currentMeasure = measure + 1;
    const measureWithNotes = trackWithNotes.find(
      (t) => t.measure === currentMeasure
    );

    return createMeasure(currentMeasure, beatsPerMeasure, measureWithNotes);
  });

  return {
    bpm,
    beatsPerMeasure,
    totalMeasures,
    totalTracks,
    timeSignature: 4,
    tracks: [createTrack(1, track)],
  };
};

export default generateTemplate;
