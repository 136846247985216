import * as Tone from 'tone';
import acousticGuitarSamples from './guitarAcoustic';
import violinSamples from './violin';

const gain = new Tone.Gain(0.5).toDestination();

export const polySynth = new Tone.PolySynth();
polySynth.connect(gain);

export const guitarAcoustic = new Tone.Sampler({
  urls: acousticGuitarSamples,
  onload: () => console.log('loaded acoustic guitar!'),
});
guitarAcoustic.connect(gain);

export const violin = new Tone.Sampler({
  urls: violinSamples,
  onload: () => console.log('loaded violin!'),
});
violin.connect(gain);

export default {};
