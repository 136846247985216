/**
 * The notes of the music scale
 */
export const scale = [
  'C',
  'C#',
  'D',
  'D#',
  'E',
  'F',
  'F#',
  'G',
  'G#',
  'A',
  'A#',
  'B',
];

/**
 * The range of octaves to be supported in the UI
 */
export const octaves = ['1', '2', '3', '4', '5', '6', '7', '8'];

export const notes = octaves
  .map((octave) => {
    return scale.map((note) => {
      return `${note}${octave}`;
    });
  })
  .flat();

/**
 * Guitar tuning
 */
export const tuning = ['E2', 'A2', 'D3', 'G3', 'B3', 'E4'].reverse();

export const guitarTunings = {
  standard: ['E4', 'B3', 'G3', 'D3', 'A2', 'E2'],
  dropD: ['E4', 'B3', 'G3', 'D3', 'A2', 'D2'],
  dropC: ['D4', 'A3', 'F3', 'C3', 'G2', 'C2'],
  openD: ['D4', 'A3', 'G3', 'D3', 'A2', 'D2'],
};

/**
 * The supported beats per measure
 */
export const beatsPerMeasureOptions = [4, 8, 16, 32, 64];

/**
 * Initial measures on new project load
 */
export const initialMeasures = 8;

/**
 * Initial beats per measure
 */
export const initialBeatsPerMeasure = 16;

/**
 * Arrays of measures and beats
 */
export const measures = [...Array(initialMeasures).keys()];
export const beats = [...Array(initialBeatsPerMeasure).keys()];

export const defaultBpm = 120;

export const instrument = 'polySynth';

export default {};
