import React, { useContext, useEffect, useState } from 'react';
import * as Tone from 'tone';
import BpmInput from './hooks/Bpm';
import { TrackContext } from './context/Track.context';
import { dispatchDomEvent } from './events/dispatchDomEvent';
import Track from './Track';
import ActionsBar from './ActionsBar';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import config from '../config/config';
import Link from './Link';
import styled from 'styled-components';
import RadioGroup from './Radio';

/**
 * Global styles
 */
import './index.css';

const Navbar = styled.nav`
  display: grid;
  grid-template-columns: 130px 80% 1fr;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: white;
`;

const StyledUtilityBar = styled.div`
  display: grid;
  grid-template-columns: 175px 105px 190px 215px;
  grid-gap: 16px;
  align-items: center;
`;

const App = () => {
  const {
    project,
    addMoreMeasures,
    setNewInstrument,
    updateProjectName,
    changeTuning,
  } = useContext(TrackContext);
  const [user, setUser] = useState(null);
  const { readOnly } = project;
  let params = new URLSearchParams(window.location.search);
  const [viewMode, setViewMode] = useState(
    window.localStorage.getItem('viewMode') || 'stacked'
  );

  useEffect(() => {
    Tone.Transport.timeSignature = project.timeSignature;
    Tone.context.lookAhead = 0.25;
  }, [project]);

  useEffect(() => {
    /**
     * Resets sessionStorage on new page load
     */
    window.sessionStorage.removeItem('highlightedNotes');
  }, []);

  /**
   * on successful login, get user info from google and send session info to API to get tracks
   */
  const responseGoogle = (response) => {
    const { profileObj } = response;

    fetch(`${config.database}/user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email_address: profileObj.email }),
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        setUser({
          email: response?.profileObj?.email,
          name: response?.profileObj?.givenName,
          userId: res.userId,
          tracks: res.tracks,
        });
      })
      .catch((e) => console.error('ERROR!'));
  };

  /**
   * on logout success, unset user
   */
  const responseGoogleLogout = () => setUser(null);

  return (
    <main>
      <Navbar>
        <h1>
          <Link href="/">tab.cafe</Link>
        </h1>
        <StyledUtilityBar>
          <ActionsBar
            template={project}
            updateProjectName={updateProjectName}
            addMoreMeasures={addMoreMeasures}
            readOnly={readOnly}
          />
          <BpmInput bpm={project.bpm} />
          <span
            style={{
              border: '1px solid black',
              padding: '0 8px',
              width: 'fit-content',
              cursor: 'not-allowed',
              opacity: 0.7,
            }}>
            Time Signature: {project.timeSignature}
          </span>
          <RadioGroup
            name="viewMode"
            opts={[
              {
                id: 'stacked',
                value: 'stacked',
                label: 'Stacked',
                checked: viewMode === 'stacked',
              },
              {
                id: 'overflow',
                value: 'overflow',
                label: 'Overflow',
                checked: viewMode === 'overflow',
              },
            ]}
            onChange={(e) => setViewMode(e.target.value)}
          />
        </StyledUtilityBar>
        <div>
          {user?.name ? <div>{user?.name} &#9660;</div> : null}
          {user ? (
            <GoogleLogout
              clientId="307568849954-v4tkptvobtq2nstd9m82gnosf7eghiqn.apps.googleusercontent.com"
              onLogoutSuccess={responseGoogleLogout}
              render={({ onClick, disabled }) => (
                <button onClick={onClick} disabled={disabled}>
                  Sign out
                </button>
              )}
            />
          ) : (
            <GoogleLogin
              clientId="307568849954-v4tkptvobtq2nstd9m82gnosf7eghiqn.apps.googleusercontent.com"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={'single_host_origin'}
              isSignedIn={true}
              render={({ onClick, disabled }) => (
                <button onClick={onClick} disabled={disabled}>
                  Sign in with Google
                </button>
              )}
            />
          )}
        </div>
      </Navbar>
      <h3
        contentEditable={!readOnly}
        onBlur={(e) =>
          dispatchDomEvent('updateTrackAttribute', {
            name: 'trackName',
            value: e.target.innerHTML,
          })
        }
        suppressContentEditableWarning
        style={{ display: 'inline-flex' }}>
        {project?.trackName}
      </h3>
      {/* New tracks will not be supported until a later point */}
      {/* <button onClick={() => addNewTrack()}>Add new track</button> */}
      <form>
        {project.tracks.map((track) => (
          <Track
            {...track}
            key={track.trackNumber}
            setNewInstrument={setNewInstrument}
            changeTuning={changeTuning}
            viewMode={viewMode}
            readOnly={readOnly}
            addMoreMeasures={addMoreMeasures}
          />
        ))}
      </form>
      {false && (
        <div>
          <h4>Selecting many notes:</h4>
          <ul>
            <li>&#8679; + &larr;/&rarr;: Highlight bars</li>
            <li>&#128045; click + drag + &larr;/&rarr;: Highlight bars</li>
            <li>Esc: Unhighlight</li>
          </ul>
          <h4>When notes are selected:</h4>
          <ul>
            <li>
              &#8963;/&#8984; + C/X with highlighted bars: Copy/Cut selection
            </li>
            <li>&#8963;/&#8984; + V with highlighted bars: Paste selection </li>
          </ul>
          <h4>When notes aren't selected:</h4>
          <ul>
            <li>&#8963;/&#8984; + C/X: Copy/Cut a note</li>
            <li>&#8963;/&#8984; + V: Paste a note </li>
            <li>&#8997; + &larr;/&rarr;/&uarr;/&darr;: move a note</li>
          </ul>
          <h4>Play the track:</h4>
          <ul>
            <li>&#8679; + Space: Play/Stop from beginning</li>
            <li>&#8679; + &#8997; + Space: Play/Stop from cursor location</li>
          </ul>
        </div>
      )}
    </main>
  );
};

export default App;
