import React from 'react';
import styled from 'styled-components';
import * as instruments from './tone/instruments';
import { guitarTunings } from './defaults';
import Measure from './Measure';
import { dispatchDomEvent } from './events/dispatchDomEvent';
import Button from './Button';

const StyledTrack = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
`;

const StyledStaffContainer = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
`;

const StyledStaff = styled.div`
  display: flex;
  flex-direction: row;
  ${({ uiIsStacked }) => uiIsStacked && `flex-wrap: wrap;`}
  padding: 16px 8px;
  overflow: scroll;
`;

const StyledTuning = styled.div`
  margin-top: 40px;
`;

const Track = ({
  trackNumber,
  instrument,
  track,
  setNewInstrument,
  tuning,
  changeTuning,
  viewMode,
  readOnly,
  addMoreMeasures,
}) => {
  return (
    <StyledTrack>
      {false && (
        <div>
          <label htmlFor={`instrument-track-${trackNumber}`}>Instrument:</label>
          <select
            value={instrument}
            id={`instrument-track-${trackNumber}`}
            onChange={(e) => setNewInstrument(e.target.value, trackNumber)}>
            {Object.keys(instruments).map((choice) =>
              choice !== 'default' ? (
                <option key={choice} value={choice}>
                  {choice}
                </option>
              ) : null
            )}
          </select>
        </div>
      )}
      {false && (
        <div>
          <label htmlFor="change-tuning">Change Tuning</label>
          <select
            id="change-tuning"
            onChange={(e) => changeTuning(e, trackNumber)}
            defaultValue={tuning}>
            <option value="standard">Standard</option>
            <option value="dropD">Drop D</option>
            <option value="dropC">Drop C</option>
            <option value="openD">Open D</option>
          </select>
        </div>
      )}
      {!readOnly && (
        <Button
          onClick={(e) => {
            e.preventDefault();
            dispatchDomEvent(`trackClear:${trackNumber}`);
          }}>
          Clear All
        </Button>
      )}
      <StyledStaffContainer>
        <StyledTuning>
          {guitarTunings[tuning].map((baseNote) => (
            <div key={baseNote} style={{ height: '24px', width: '18px' }}>
              {baseNote}
            </div>
          ))}
        </StyledTuning>
        <StyledStaff uiIsStacked={viewMode === 'stacked'}>
          {track.map(({ measure, bars }) => {
            return (
              <Measure
                measure={measure}
                bars={bars}
                key={`measure_${measure}`}
                trackNumber={trackNumber}
                readOnly={readOnly}
              />
            );
          })}
          {!readOnly && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                addMoreMeasures();
              }}
              style={{ fontSize: '24px', textDecoration: 'none' }}>
              +
            </Button>
          )}
        </StyledStaff>
      </StyledStaffContainer>
    </StyledTrack>
  );
};

export default Track;
