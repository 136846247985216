import React from 'react';
import styled from 'styled-components';

const StyledPseudoButton = styled.div`
  display: inline-block;
  ${({ checked }) =>
    checked
      ? `
      background-color: black;
      color: white;
    `
      : `
      color: black;
      background-color: white;
      `}
  transition: background-color 0.1s linear, color 0.2s linear;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid black;
  }

  border-radius: 3px;
`;

const StyledLabel = styled.label`
  padding: 8px 16px;
  cursor: pointer;
  display: block;
`;

const StyledRadio = styled.input`
  position: absolute;
  left: -999999px;
  visibility: hidden;
  height: 0;
  width: 0;
`;

const RadioGroup = ({ opts = [], name, onChange }) => {
  return (
    <div>
      {opts.map((opt) => (
        <StyledPseudoButton checked={opt.checked} key={opt.id}>
          <StyledLabel htmlFor={opt.id}>{opt.label}</StyledLabel>
          <StyledRadio
            id={opt.id}
            type="radio"
            value={opt.value}
            onChange={onChange}
            name={name}
          />
        </StyledPseudoButton>
      ))}
    </div>
  );
};

export default RadioGroup;
